<template>
	<v-row
			no-gutters
			style="position: relative"
			align="center"
			@focus="focus = true"
			@blur="focus = false"
	>
		<Datetime
				v-model="datas"
				:placeholder="placeholder"
				:title="label"
				:class="[$vuetify.theme.dark ? 'vdatetime-dark ' + (outlined ? 'outlined':''):'vdatetime-light ' + (outlined ? 'outlined':''), focus ? 'focus':'']"
				format="yyyy-MM-dd hh:mm"
				type="datetime"
				style="height: 30px; width: 100%"
				auto
		>
			<template slot="button-cancel">
				{{ $tc('global.action.cancel', 1) }}
			</template>
			<template slot="button-confirm">
				{{ $tc('global.action.validate', 1) }}
			</template>
		</Datetime>
		<v-btn
				small
				color="transparent"
				style="position: absolute; right: 4px"
				v-show="datas !== '' && clearable"
				icon
				@click="test()"
		>
			<v-icon size="18" color="black2white lighten-5">mdi-close</v-icon>
		</v-btn>
	</v-row>
</template>

<script>
	import { Datetime } from 'vue-datetime';
	import { Settings } from 'luxon'
	import 'vue-datetime/dist/vue-datetime.css'

	export default {
		name: 'TimePicker',
		props: {
			value: {default: null}, 
			placeholder: {},
			label: {},
			outlined:{},
			clearable:{}
			},
		components: {
			Datetime
		},
		data(){
			return {
				focus: false
			}
		},
		computed: {
			datas:{
				get(){
					return this.value || ""
				},
				set(val){
					this.$emit('change', val)
					if (val === ""){
						this.$emit('input', null)
					}
					else {
						this.$emit('input', val)
					}
					
				}
			}
		},
		methods: {
			test(){
				this.datas = null
			}
		},
		created(){
			Settings.defaultLocale = this.$store.getters["profile/user_language"].locale
		}
	}
</script>
